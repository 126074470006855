import React from "react"

export default function() {
  return (
    <section style={{margin: 0}}>
        <div
          className="col-md-12"
          style={{background: "#58b0da29",color: "#443636",paddingTop: "3em",paddingBottom: "2em"}}
        >
          <div style={{width: "100%"}}>
              <p>
              Sheer Analytics and Insights is a global market research and consulting firm that offers a wide range of services, including SEO and translation services. Here are some details on the SEO and translation services offered by Sheer Analytics and Insights:
              </p>
              <br />
              <h1>SEO Services</h1>
              <p>Sheer Analytics and Insights provides a range of SEO services to help businesses improve their online visibility and reach their target audience. Some of the SEO services offered by Sheer Analytics and Insights include:</p>
              <p>
                •	Keyword research and analysis
                •	On-page optimization
                •	Off-page optimization
                •	Local SEO
                •	Content optimization
                •	SEO audits and reporting
              </p>
              <p>The SEO services offered by Sheer Analytics and Insights are tailored to meet the specific needs of each client. They help businesses increase their website traffic, improve their search engine rankings, and achieve their online marketing goals.</p>
              <h1>Translation Services</h1>
              <p>Sheer Analytics and Insights offers professional translation services to help businesses communicate with their global audience effectively. The translation services offered by Sheer Analytics and Insights include:</p>
              <p>
                •	Document translation
                •	Website translation
                •	Legal translation
                •	Medical translation
                •	Technical translation
              </p>
              <p>The translation services provided by Sheer Analytics and Insights are carried out by professional translators who are native speakers of the target language. They ensure that the translated content is accurate, culturally appropriate, and meets the client's requirements.</p>
          </div>
          <p>For any queries reach us at <a href="mailto:st@sheeranalyticsandinsights.com">st@sheeranalyticsandinsights.com</a></p>
        </div>
    </section>
  )
}
